<div class="footer-container large-footer">
  <footer class="large-footer">
    <div class="footer-contain large-footer">
      <div class="company-logo large-footer">
        <img src="/assets/Logo/logo-column.svg" alt="logo" />
      </div>


      <div class="footer-text-res text-large text-no-wrap large-footer">
        Toate drepturile rezervate ©2024
      </div>

      <div class="social-media-container large-footer">
        <!-- Twitter -->
        <a class="footer-social-link text-xxxlarge40 color-white large-footer" href="#!" role="button" target="_blank"
          data-mdb-ripple-color="dark">
          <i class="fa-brands fa-x-twitter"></i>
        </a>

        <!-- Instagram -->
        <a class="footer-social-link text-xxxlarge40 color-white large-footer" href="#" role="button" target="_blank"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-instagram"></i>
        </a>
        <!-- Facebook -->
        <a class="footer-social-link text-xxxlarge40 color-white large-footer" href="https://facebook.com/intarotrophy" role="button" target="_blank"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-facebook-f"></i>
        </a>

        <!-- Linkedin -->
        <a class="footer-social-link text-xxxlarge40 color-white large-footer" href="https://linkedin.com/company/intarotrophy " role="button" target="_blank"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  </footer>
</div>

<div class="footer-container small-footer">
  <footer class="small-footer">
    <div class="footer-contain small-footer">
      <div class="company-logo small-footer">
        <img src="/assets/Logo/logo-column.svg" alt="logo" />
      </div>


      <div class="footer-text-res text-xsmall small-footer">
        Toate drepturile rezervate ©2024
      </div>

      <div class="social-media-container small-footer">
        <!-- Twitter -->
        <a class="footer-social-link text-xxlarge color-white small-footer" href="#!" role="button"
          data-mdb-ripple-color="dark">
          <i class="fa-brands fa-x-twitter"></i>
        </a>

        <!-- Instagram -->
        <a class="footer-social-link text-xxlarge color-white small-footer" href="#" role="button"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-instagram"></i>
        </a>
        <!-- Facebook -->
        <a class="footer-social-link text-xxlarge color-white small-footer" href="#" role="button"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-facebook-f"></i>
        </a>

        <!-- Linkedin -->
        <a class="footer-social-link text-xxlarge color-white small-footer" href="#" role="button"
          data-mdb-ripple-color="dark" target=”_blank”>
          <i class="fab fa-linkedin"></i>
        </a>
      </div>

    </div>
  </footer>
</div>