<div class='pa-container intaro-tr' id="partnersPage">
    <div class="company-logo">
        <img src="/assets/Logo/logo-column.svg" alt="logo" />
    </div>
    <div class="partners-container">
        <img src="/assets/Logo/partners/IntaroLogo.svg" alt="logo" />
        <img src="/assets/Logo/partners/FRT.svg" alt="logo" />
        <img src="/assets/Logo/partners/MINDTHEGAP.svg" alt="logo" />
        <img src="/assets/Logo/partners/fizinova.svg" alt="logo" />
        <img src="/assets/Logo/partners/Hotel.svg" alt="logo" />
        <img src="/assets/Logo/partners/Triplast.svg" alt="logo" />
        <img src="/assets/Logo/partners/raiffeisen.svg" alt="logo" />
    </div>
</div>