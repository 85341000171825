<nav class="navbar navbar-expand-lg nav-custom">
  <div class="container-fluid">
    <a class="navbar-brand nav-custom" href="/home">
      <img src="../../../assets/Logo/Logo.png" alt="Company Logo" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse nav-custom" id="navbarNavDropdown">
      <ul class="navbar-nav nav-custom">
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" aria-current="page" href="/home">Home</a>
        </li>
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" href="/news">News</a>
          <!-- <a class="nav-link text-large" aria-current="page" href="/news">News</a> -->
        </li>
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" href="/program">Program</a>
        </li>
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" href="/results">Results</a>
        </li>
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" (click)="handleLinkClick('galleryPage')">Gallery</a>
        </li>
        <!-- <li class="nav-item nav-custom">
          <a class="nav-link text-large" href="/aboutUs">AboutUs</a>
        </li> --> 
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" (click)="handleLinkClick('partnersPage')">Partners</a>
        </li>
        <li class="nav-item nav-custom">
          <a class="nav-link text-large" (click)="handleLinkClick('contactPage')">Contact</a>
          <!-- <a class="nav-link text-large" href="/contact">Contact</a> -->
        </li>

      </ul>
    </div>
  </div>
</nav>