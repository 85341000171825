<div class='pr-container intaro-tr'>

    <div class="carousel-wrapper">
        <button class="carousel-button prev" id="scrollLeftBtn2">&#10094;</button>

        <div class="days-container" id="daysContainer" #daysContainer>
            <div *ngFor="let day of days" [ngClass]="{ 'current': day.date === selectedDate }" (click)="selectDay(day)">
                <div class="day-title title-xxlarge28">{{ day.title }}</div>
                <div class="day-date text-xxxlarge32">{{ day.date }}</div>
            </div>
        </div>

        <button class="carousel-button next" id="scrollRightBtn2">&#10095;</button>
    </div>


    <div class="games-container intaro-tr">
        <div *ngFor="let playerVsPlayer of playerVsPlayers" class="game-container">
            <div class="players-container">
                <div class="title-xllarge text-no-wrap ic-bold">{{ playerVsPlayer.namePlayer1 }}</div>
                <div class="title-xllarge text-no-wrap ic-bold">{{ playerVsPlayer.namePlayer2 }}</div>
            </div>
            <div class="score-container">
                <div class="title-xllarge text-no-wrap">{{ playerVsPlayer.scorePlayer1 }}</div>
                <div class="title-xllarge text-no-wrap">{{ playerVsPlayer.scorePlayer2 }}</div>
            </div>
            <div class="data-container">
                <div class="title-xllarge text-no-wrap">
                    <span class="text-xllarge text-no-wrap data-text"> ora </span>
                    <span class="title-xllarge text-no-wrap data-text ic-bold">{{ playerVsPlayer.gameHour }}</span>
                </div>
                <div class="text-xllarge text-no-wrap">
                    <span class="data-text text-xllarge"> terenul </span>
                    <span class="data-text">{{ playerVsPlayer.field }}</span>
                </div>
            </div>
        </div>
    </div>
</div>